import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
export const query = graphql`
  {
    mdx(slug: { eq: "what-nutrition-apps-work-with-fitbit/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const Link = makeShortcode("Link");
const AffiliateLink = makeShortcode("AffiliateLink");
const AppImage = makeShortcode("AppImage");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> In order to lose fat or build
  muscle, you must control your calorie intake. Either a deficit or a surplus
  must be achieved respectively. Calorie counters are very effective to
  determine the calorie intake. But what if, on the other hand, your calorie
  consumption varies from day to day? This article describes how ShapeGanic's
  Fitbit integration solves this issue.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Don't miss out on these benefits...`}</h2>
    <p>
  Tracking your calories is important, as is regularly recording your body
  weight. The latter is especially important to determine the effectiveness of
  your diet. However, to lose fat or gain muscle, two factors are important:{" "}
  <b>a) calorie intake</b> and <b>b) calorie expenditure</b>. For example, if
  you want to build muscle, but your calorie consumption is too high, you will
  tend to lose weight despite a high calorie intake. At the same time, you can
  lose body fat not only by reducing your calorie intake, but also by increasing
  your calorie consumption.
    </p>
    <p>
  This issue is pretty challenging which is why ShapeGanic provides a free{" "}
  <Link url={props.pageContext.frontmatter.fitbit_url} mdxType="Link">
    {props.pageContext.frontmatter.fitbit_text}
  </Link>{" "}
  integration. With this, you can access your activity values recorded with a{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_fitbit_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_fitbit_text}
  </AffiliateLink>
  , such as your basal metabolic rate, total and activity calories. You also get
  both read and write access to your Fitbit weight log. So you can not only inspect
  weight log values tracked with Fitbit, but also add, change, and delete them. All
  of this is automatically synchronized with the Fitbit platform.
    </p>
    <h3>{`It's really that simple!`}</h3>
    <p>
  Connecting to Fitbit is a no brainer. You'll either see banners in the
  dashboard pointing you to this feature, or you can go into the ShapeGanic
  settings. These are accessible from almost any screen. As you can see on the
  following picture, you just have to click on the top menu item. After that you
  will be redirected to Fitbit in a browser window. Here you have to enter your
  Fitbit username (email address) and password. Afterwards you will be asked if
  you want to give ShapeGanic access to the displayed data. Please check all
  boxes and grant ShapeGanic access to the corresponding data types. This is the
  only way to ensure smooth functionality.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  Your data obtained from Fitbit is only stored locally on your phone and is
  never sent to our servers or to third parties. Data protection has top
  priority for ShapeGanic! You have the possibility to revoke ShapeGanic's
  access to your Fitbit data at any time. You can do this either via the Fitbit
  app or via the Fitbit web portal.
    </p>
    <h2>{`This feature makes your life easier...`}</h2>
    <p>
  You're in the middle of a strict diet and you're hungry on a regular basis.
  And you think of nothing but a large pizza, but at the same time do not want
  to sabotage your diet. Then simply increase your daily calorie limit for one
  day, e.g. by <b>1000 kcal</b>, by being very active and consequently burning
  activity calories. ShapeGanic offers you the option to add activity calories
  obtained from Fitbit to your calorie limit and proportionally to your
  macronutrient limits. This way your calorie limits are not static, but
  automatically adjust to your daily activity level!
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  With this adaptive approach, your diet will be a breeze and you'll have the
  ability to "go off the rails" while successfully dieting.
    </p>
    <h2>{`BMR, activity and total calories - Everything at a glance`}</h2>
    <p>
  With so many numbers, a clear presentation makes the difference between
  success and failure! BMR, activity and total calories are clearly displayed
  with deviation from the previous day. Either as a weekly or monthly overview,
  you get a chronological progression of the respective values. This way you can
  track your activity level as well as your calorie consumption for the selected
  day and period.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  Distinctive color codes and simple symbols make the interface easy to
  understand at a glance. This allows you to evaluate your nutrition as well as
  your activity values over time.
    </p>
    <h2>{`Your weight log composed in one place`}</h2>
    <p>
  ShapeGanic provides an easy way to track your body weight. Do you also use the
  weight diary features of the Fitbit app or even a Fitbit compatible scale and
  do you want to access all sources of weight data from one place?
    </p>
    <p>
  With ShapeGanic you can easily access your Fitbit weight entries. You have
  both read and write access. This means you can not only view your weight
  entries as shown in the image below, but also add, change or even delete
  entries. These events are synchronized with Fitbit.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} mdxType="AppImage" />
    <p>
  You have everything you need to achieve your fitness goals and get one step
  closer to getting in shape. You can extensively evaluate your nutrition, your
  physical activity, as well as your body weight and make optimizations based on
  that. And all these features are unrestricted and 100% free of charge!
    </p>
    <Disclosure mdxType="Disclosure" />
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      